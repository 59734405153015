/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li",
    strong: "strong",
    em: "em",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Many ", React.createElement(_components.a, {
    href: "https://openviewpartners.com/product-led-growth",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "SaaS companies are flourishing"), " with the product-led growth (PLG) model:"), "\n", React.createElement(_components.p, null, "\"[T]here are 21 large public companies with a PLG model — including all of the top IPOs in 2019. That number continues to rise as more PLG companies IPO each year.\""), "\n", React.createElement(_components.p, null, "We understand that every SaaS company, by nature of its products and customers, cannot simply pour itself into a PLG mold to start realizing exponential growth. SaaS companies are at different product and market maturity stages and may have substantial, profitable momentum that an abrupt switch to PLG might jeopardize."), "\n", React.createElement(_components.p, null, "But even if you are not leading the charge for a PLG transformation at your company, you've probably seen the benefits of implementing certain aspects of PLG without realigning the entire company for PLG."), "\n", React.createElement(_components.p, null, "One thing you can do to move your app toward a more user-focused approach (the heart of PLG) is to provide native integrations. While integrations are often afterthoughts for developers and users, they shouldn't be. If you implement ", React.createElement(_components.a, {
    href: "/resources/build-vs-buy-adding-native-integrations-to-your-product/"
  }, "native integrations"), " for your app, your end users will benefit from less friction and greater productivity and see that your application actively contributes to making their work easier."), "\n", React.createElement(_components.p, null, "But, before we get into all the details, let's step back and review the product-led growth model at a high level."), "\n", React.createElement(_components.h2, {
    id: "what-is-product-led-growth",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-product-led-growth",
    "aria-label": "what is product led growth permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is product-led growth?"), "\n", React.createElement(_components.p, null, "At its simplest, we can describe PLG as using the product as the primary means of getting new customers and keeping old customers happy. A company that is following the PLG model should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Design for the end user."), " Earlier, we mentioned that a user-focused approach is the heart of PLG. Two big-picture things that support this: letting the user's business needs drive your product and making (and keeping) an excellent UX as your top priority."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Deliver value quickly."), " A web browser search engine is a perfect example of providing quick value: perform a search and receive relevant results. We can expand the concept of delivering value quickly to making the cost of entry small, solving immediate issues, and matching pricing to the provided value."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Invest in the product intelligently."), " We could almost define this as: ", React.createElement(_components.em, null, "keep designing for the end user and delivering value quickly"), ". Intelligent investment entails building out the team with a product-for-the-user focus, iterating/trying things quickly, and collecting and studying product data to reduce end user friction and continuously improve product usefulness."), "\n"), "\n", React.createElement(_components.h2, {
    id: "how-does-adding-integrations-support-these-goals",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-does-adding-integrations-support-these-goals",
    "aria-label": "how does adding integrations support these goals permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How does adding integrations support these goals?"), "\n", React.createElement(_components.p, null, "A company that implements the PLG model focuses intently on making and keeping the user happy with the product. Here are a few ways that adding integrations can help you move your product in the PLG direction."), "\n", React.createElement(_components.h3, {
    id: "integrations-increase-the-effective-use-of-end-users-time",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integrations-increase-the-effective-use-of-end-users-time",
    "aria-label": "integrations increase the effective use of end users time permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integrations increase the effective use of end users' time"), "\n", React.createElement(_components.p, null, "When end users work with your app, they use the subset of functionality that pertains to their own jobs. Data entry is usually a piece of that, and while few people enjoy data entry, we all understand its necessity. However, when you provide integrations with your app, you immediately decrease the time users spend performing data entry, whether in your app or another app your customers use."), "\n", React.createElement(_components.p, null, "With more data entry being performed programmatically, users can quickly realize the value of implementing your app because they can spend their time higher-value work."), "\n", React.createElement(_components.h3, {
    id: "integrations-make-things-sticky-for-your-customers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integrations-make-things-sticky-for-your-customers",
    "aria-label": "integrations make things sticky for your customers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integrations make things sticky for your customers"), "\n", React.createElement(_components.p, null, "Once you've shown your customers that you can provide one (or two, or five) integrations, they will depend not just on your app but also on its native integrations. Building integrations isn't easy, and customers know this. Many of them have probably had difficult experiences trying to roll their own. Proving to your customers that you can provide them with needed integrations ensures that they stick around."), "\n", React.createElement(_components.p, null, "And the more integrations you can provide to your customer, the greater the customer investment and the higher the business cost of them going elsewhere. Your product and all the integrations tied to your product become deeply embedded in your customers' day-to-day processes. In the best possible way, they have become dependent on your product."), "\n", React.createElement(_components.h3, {
    id: "integrations-lead-naturally-to-upsell-opportunities",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integrations-lead-naturally-to-upsell-opportunities",
    "aria-label": "integrations lead naturally to upsell opportunities permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integrations lead naturally to upsell opportunities"), "\n", React.createElement(_components.p, null, "Once your customers have subscribed to your core app, subscribing to native integrations for your app is a simple next step. Users would rather turn on an integration precisely suited to their task than build the integration themselves or manually update multiple third-party systems."), "\n", React.createElement(_components.p, null, "As your customers keep growing, so do their integration needs. Building and selling integrations is an excellent way to expand revenue within your existing customer base."), "\n", React.createElement(_components.h2, {
    id: "adding-integrations-sounds-great-for-plg-but-whats-the-catch",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#adding-integrations-sounds-great-for-plg-but-whats-the-catch",
    "aria-label": "adding integrations sounds great for plg but whats the catch permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Adding integrations sounds great for PLG, but what's the catch?"), "\n", React.createElement(_components.p, null, "The catch is that doing integrations well isn't usually easy. The time and effort to build out native integrations might require a substantial increase in your development and infrastructure budgets, and it could also derail other product plans."), "\n", React.createElement(_components.p, null, "Fortunately, an ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), " (or ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), ") can help you mitigate many risks of rolling out native integrations while still striving toward the PLG goals we discussed above. A comprehensive embedded iPaaS includes:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A low-code integration designer that empowers non-developers to build productized integration workflows that can be configured and deployed to multiple customers."), "\n", React.createElement(_components.li, null, "A library of built-in components that reduces the effort of building integrations by providing connectivity to many common SaaS apps, as well as common integration logic functions, without the need to write code. In addition, the embedded iPaaS allows developers to build custom components to connect with any app or service."), "\n", React.createElement(_components.li, null, "An integration marketplace that teams can white-label and embed in their product to provide a self-activated, in-app integration experience for end users."), "\n", React.createElement(_components.li, null, "Integration deployment and support tools that enable customer-facing teams to configure, deploy, monitor, and troubleshoot customers' integrations without engineering involvement."), "\n", React.createElement(_components.li, null, "A cloud infrastructure that runs integrations and handles scalability, security, and compliance concerns."), "\n"), "\n", React.createElement(_components.h2, {
    id: "using-an-embedded-ipaas-allows-you-to-do-even-more-toward-plg",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-an-embedded-ipaas-allows-you-to-do-even-more-toward-plg",
    "aria-label": "using an embedded ipaas allows you to do even more toward plg permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using an embedded iPaaS allows you to do even more toward PLG"), "\n", React.createElement(_components.p, null, "An embedded iPaaS is a force multiplier for your integration initiative. You'll still need to design, build, support, and iterate your integrations, but your devs and non-devs will have a comprehensive integration toolset to make that happen. Here are a couple of ways that using an embedded iPaaS for integrations moves your product even further down the path toward PLG:"), "\n", React.createElement(_components.h3, {
    id: "user-experience-is-greatly-enhanced",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#user-experience-is-greatly-enhanced",
    "aria-label": "user experience is greatly enhanced permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "User experience is greatly enhanced"), "\n", React.createElement(_components.p, null, "With the themeable, white-label ", React.createElement(_components.a, {
    href: "/platform/embedded-integration-marketplace/"
  }, "marketplace"), ", end users can research, select, configure, and activate their integrations. Talk about putting the power of the product into the end user's hands! There is no need to reach out to your users to tell them what's available when you can publish the catalog of available integrations (or even coming-soon integrations) to a page inside your app."), "\n", React.createElement(_components.p, null, "In addition to selecting and activating the integrations, end users can also use integration logs and alerts to monitor their integrations and perform first-level troubleshooting. This lets them correct invalid config parameters or restart an integration because a third-party app went offline – all without needing to contact your support team or open a trouble ticket."), "\n", React.createElement(_components.p, null, "As a result of providing your integrations within your app and making them easily accessible to end users, they will cease to think of your app and your integrations as separate things. Good UX in your app and good UX for app integrations increases end user happiness."), "\n", React.createElement(_components.h3, {
    id: "integration-roll-out-is-considerably-faster",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integration-roll-out-is-considerably-faster",
    "aria-label": "integration roll out is considerably faster permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integration roll-out is considerably faster"), "\n", React.createElement(_components.p, null, "The less time it takes you to successfully ", React.createElement(_components.a, {
    href: "/solutions/onboard-customers-faster/"
  }, "onboard an end user"), ", the faster that person can see value from your app. It's fantastic that you can spin up new users in your app in minutes. But what if the integrations the user needs take additional weeks, or even months, to implement? That user's initial euphoric reaction of \"This is cool!\" is long gone, and they just want the integrations to work. By having your team leverage an embedded iPaaS, you'll be able to shorten the time-to-value for integrations to a matter of days instead of weeks or months."), "\n", React.createElement(_components.p, null, "In addition, when your users come to you with new integration requests, you'll be able to turn those requirements into integrations more quickly with the embedded iPaaS. After all, you do not need to build out any ", React.createElement(_components.a, {
    href: "/platform/purpose-built-infrastructure/"
  }, "infrastructure"), " or do any of the myriad things that bespoke integrations require. You'll probably already have integration patterns that you can use as a starting point for new integrations."), "\n", React.createElement(_components.p, null, "When using an embedded iPaaS for integrations, you'll also be able to take advantage of building integrations for scale by ", React.createElement(_components.a, {
    href: "/solutions/productize-your-integrations/"
  }, "productizing"), " them. With the ", React.createElement(_components.a, {
    href: "/resources/integration-marketplace-for-b2b-saas/"
  }, "integration marketplace"), ", you can make a single integration available to all your customers and define unique configuration variables for each customer. Write an integration once, and deploy it to dozens, if not hundreds of customers in the time it used to take to deploy an integration to a single customer. You'll be able to increase your revenue without needing to grow your integration team by the same factor."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "As we've seen, PLG is an effective model for many SaaS companies. Not every company can implement a cookie cutter product led growth model, but there are elements of PLG that any company can implement. One way to move your product (and company) toward a product led growth model is to focus on building ", React.createElement(_components.a, {
    href: "/resources/build-vs-buy-adding-native-integrations-to-your-product/"
  }, "native integrations"), " for your customers."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/contact/"
  }, "Contact us"), " or request a ", React.createElement(_components.a, {
    href: "/request-a-demo/"
  }, "demo"), " to see how Prismatic's ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), " can help you launch a proven, scalable strategy for providing native integrations to your customers."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
